<template>
  <div>
    <div class="nav">
      <div class="nav-1" @click="toList">我的订单</div>
      <div class="nav-icon"></div>
      <div class="nav-2">订单 {{ orderId }}</div>
    </div>
    <CompHeader title="订单详情" />
    <detailsHeader
      v-if="state.orderInfo"
      @openapp="appFn"
      type="product"
      :orderInfo="state.orderInfo"
    />
    <detailsAddress
      v-if="state.orderInfo"
      @openapp="appFn"
      type="product"
      :orderInfo="state.orderInfo"
    />
    <details-object-content
      v-if="state.orderInfo"
      :orderInfo="state.orderInfo"
      @openboxmore="openboxmore"
    />
  </div>
  <OpenApp ref="openapp" />
</template>

<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  ref,
  // eslint-disable-next-line no-unused-vars
  watch,
  onMounted,
  reactive,
  // eslint-disable-next-line no-unused-vars
  defineExpose,
  // eslint-disable-next-line no-unused-vars
  computed,
  onBeforeUnmount,
} from "vue";
// eslint-disable-next-line no-unused-vars
import { getOrderDetailV2 } from "@api";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import CompHeader from "@@/common/header.vue";
import OpenApp from "@@/common/openApp.vue";
import { ElMessage } from "element-plus";
import detailsHeader from "@@/order_detail/detailsHeader.vue";
import detailsAddress from "@@/order_detail/detailsHeaderAddress.vue";
import detailsObjectContent from "@@/order_detail/detailsObjectContent.vue";
// eslint-disable-next-line no-unused-vars
const store = useStore();
const router = useRouter();
const route = useRoute();
const openapp = ref(null);
let orderId = ref("");
let style = "";
onMounted(() => {
  orderId.value = route.query.order_id || "";
  style = route.query.style || "";
  getDetails();
});
onBeforeUnmount(() => {});
const state = reactive({
  loading: true,
  list: [],
  orderInfo: null,
  page: 1,
  postCount: 0,
  nav: 100,
});
function appFn () {
  openapp.value.open()
}
const toList = () => {
  router.push("/order/order_list");
};
const getDetails = async () => {
  try {
    const res = await getOrderDetailV2({
      id: orderId.value,
      type: style ? "pay_id" : "order_id",
      pro_id: "",
    });
    if (res.status !== 0) {
      return ElMessage({
        showClose: true,
        message: res.message,
        type: "error",
      });
    }
    state.orderInfo = res.data;
  } catch (e) {
    console.log(e);
  }
};
</script>
<style  lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .nav-1 {
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
    cursor: pointer;
  }
  .nav-icon {
    margin: 0 5px;
    width: 16px;
    height: 16px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover
      no-repeat;
  }
  .nav-2 {
    font-size: 16px;
    color: #222c37;
    font-weight: bold;
  }
}
</style>

<style scoped lang="scss">
.goodInfo {
  margin-top: 20px;
  position: relative;
  border: 1px solid #dee0e1;
  background: #ffffff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-flow: row;

  .title {
    display: flex;
    align-items: center;
    height: 120px;
    font-size: 18px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    padding: 0 20px;
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    border-bottom: 1px solid #dee0e1;
    cursor: pointer;

    span {
      font-weight: bold;
      padding: 0 20px;
    }

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      color: #7a8087;
    }

    .sixin {
      width: 129px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #b1b1b1;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #7f7f7f;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
    }
  }

  .good_card_list {
    padding: 0 20px;
    position: relative;
    border-right: 1px solid #dee0e1;
  }

  .trade_data {
    margin-top: 15px;

    .reward_name {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 24px;
      display: flex;
      justify-content: space-between;

      .right {
        display: flex;
        justify-content: space-between;
        flex: 1;
        margin-left: 58px;

        .name {
          flex: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }

      .num {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 24px;
        margin-left: 10px;
      }
    }

    .reward_is_customized {
      padding-left: 106px;
      margin-top: 2px;
      font-size: 14px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #f5a728;
      line-height: 21px;
    }

    .openbox-text {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b1b1b1;
      line-height: 21px;
      padding-top: 8px;

      p {
        margin: 0;
        margin-top: 8px;
        padding-left: 64px;
      }
    }

    .p {
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #7f7f7f;
      line-height: 21px;
      padding-left: 106px;
    }

    .rew_logo {
      margin-top: 17px;
      padding-left: 106px;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      ::v-deep img {
        height: 78px;
        background: #dcdcdc;
        border-radius: 4px;
        margin-right: 9px;
        border: 1px solid #f2f2f2;

        &:nth-of-type(3n) {
          margin-right: 0px;
        }
      }
    }

    ::v-deep .together_detail {
      position: relative;
      display: flex;
      margin: 0;
      border-top: 1px solid #e8e8e8;
      padding: 0px;
      padding-bottom: 0;
      margin-top: 16px;
      padding-top: 16px;

      div {
        box-sizing: content-box;
      }

      &.three {
        overflow: hidden;
        height: max-content;
      }

      &.two {
        overflow: hidden;
        height: max-content;
        // height: 1.333333rem;
      }

      .item_right {
        width: calc(100% - 0.52rem);
        width: -webkit-calc(100% - 0.52rem);
        height: 100%;
        overflow: hidden;
      }

      .item_right .item_col {
        min-height: 24px;
        padding-bottom: 13px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
      }

      .item_right .item_col p {
        margin: 0;
        line-height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1a1a1a;
        line-height: 24px;
        display: flex;
        align-items: center;

        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 10px;
          // background: url("~@assets/images/orderlist/orderDetailsIcon3.png")
          //   center/cover no-repeat;
        }
      }

      .item_right .item_col:last-child {
        padding: 0;
        // color: #9da1a7;
        // float: right;
      }

      .item_right .item_col p:last-child {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
      }

      .item_left {
        width: 8px;
        margin-right: 8px;
        position: relative;
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      .item_left .line {
        width: 2px;
        height: 29px;
        background: linear-gradient(90deg,
            rgba(177, 177, 177, 1) 0%,
            rgba(177, 177, 177, 1) 100%);

        &.activity {
          background: linear-gradient(180deg,
              rgba(177, 177, 177, 1) 0%,
              rgba(0, 196, 161, 1) 100%);
        }

        &.activityF {
          background: linear-gradient(0deg,
              rgba(177, 177, 177, 1) 0%,
              rgba(0, 196, 161, 1) 100%);
        }

        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0;

        span {
          display: block;
          width: 2px;
          background: #f8f8f8;
          height: 3px;
        }
      }

      .item_left .c {
        width: 8px;
        height: 8px;
        background: #bcbfc3;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        overflow: hidden;
      }

      .item_left .c.active {
        background: #00d6a6;
      }

      .item_left .c.c1 {
        top: 0.053333rem;
      }

      .item_left .c.c2 {
        top: 50%;
      }

      .item_left .c.c3 {
        bottom: 0.053333rem;
      }
    }
  }

  .reward_time {
    display: flex;
    justify-content: space-between;
    height: 24px;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 24px;
    margin-top: 14px;
    margin-bottom: 17px;
  }

  .freight {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 24px;
    margin-top: 12px;

    .copyp {
      display: flex;
      align-items: center;

      .fuzhi {
        margin-left: 5px;
        min-width: 40px;
        height: 28px;
      }
    }

    &:nth-of-type(1) {
      margin-top: 20px;
    }

    span {
      &:nth-of-type(2) {
        font-weight: 400;
        color: #1a1a1a;
      }

      &.price2 {
        color: #ff3535;
        font-size: 20px;
        font-weight: bolder;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
    padding: 0 20px;

    >span {
      margin-left: 16px;
      font-size: 20px;
      font-family: Avenir-Heavy, Avenir;
      font-weight: 800;
      color: #ff3535;

      .ico {
        font-size: 20px;
      }
    }
  }

  .service {
    margin-top: 40px;
    display: block;
    text-align: center;
    color: #00cba6;
    font-weight: normal;
    line-height: 88px;
    height: 88px;
    width: calc(100% + 60px);
    margin-left: -0.4rem;
    border-top: 2px solid #f2f2f2;
  }

  .openbox-result {
    margin-top: 20px;
    padding: 30px;
    background: #f8f8f8;
    border-radius: 8px;
    width: 100%;

    .openbox-title {
      display: flex;
      justify-content: space-between;
      height: 28px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1a1a1a;
      line-height: 28px;

      .num {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 28px;
        display: flex;
        align-items: center;

        .iconfont {
          width: 24px;
          height: 24px;
          color: #1a1a1a;
        }
      }
    }

    .openbox-ul {
      margin-top: 30px;
      display: flex;

      .openbox-li {
        position: relative;
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }

        .unpacking_type {
          position: absolute;
          top: 0px;
          right: 0;
          width: 82px;
          height: 34px;
          background: #00c4a1;
          line-height: 34px;
          text-align: center;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 400;
          z-index: 1;
          color: #f8f8f8;
          border-radius: 0px 8px 0px 8px;

          &.unpacking_type1 {
            display: block;
          }
        }

        .img {
          position: relative;
          width: 200px;
          height: 280px;
          border-radius: 8px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }

          .more {
            width: 100%;
            height: 100%;
            background: rgba(000, 000, 000, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .name {
          margin-top: 10px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
        }
      }
    }
  }

  .freight-box {
    padding-bottom: 17px;
  }

  .remark {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dee0e1;

    .left {
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;
      line-height: 24px;
    }

    .remarktext {
      width: 210px;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;
      line-height: 24px;
    }
  }

  .order_paper {
    border-top: 1px solid #dee0e1;
  }
}
</style>

<template>
  <div class="goodInfo">
    <div class="title" v-if="orderInfo.product_info" @click.stop="goProDetail(orderInfo.product_info.id)">
      <img class="icon" :src="orderInfo.product_info.wb_logo_1" />
      <span>{{ orderInfo.product_info.name }}</span>
      <!-- <div class="sixin">私信发起者</div> -->
    </div>
    <div class="good_card_list" v-if="orderInfo.product_info">
      <div class="trade_data">
        <template v-if="orderInfo.trade_data.reward_id !== -3">
          <div class="reward_name">
            <div class="left">回报档</div>
            <div class="right">
              <span class="name">
                ¥{{ orderInfo.trade_data.reward_money }}（
                {{ orderInfo.trade_data.reward_name }}）
              </span>
              <span class="num">×{{ orderInfo.trade_data.apply_total }}</span>
            </div>
          </div>
          <div class="reward_is_customized" v-if="orderInfo.trade_data.is_customized">
            *该产品为定制产品，众筹成功后不支持无理由退货退款
          </div>
        </template>
        <template v-else>
          <div class="reward_name">
            <!-- <div class="left">回报档</div> -->
            <div class="right">
              <span class="name">
                {{ orderInfo.trade_data.reward_name }}
              </span>
            </div>
          </div>
          <template v-if="orderInfo.order_type === 2">
            <div class="together_detail two" v-if="orderInfo.status < 201">
              <div class="item_left">
                <span class="c c1 active"></span>
                <span class="line activityF">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="c c3"></span>
              </div>
              <div class="item_right">
                <div class="item_col">
                  <p>预付金额 ¥{{ orderInfo.pay_amount }}</p>
                  <p>未付款</p>
                </div>
                <div class="item_col">
                  <p>实际配捐</p>
                  <p>未生成</p>
                </div>
              </div>
            </div>
            <div v-else-if="!orderInfo.teamfund_refund_amount" class="together_detail two">
              <div class="item_left">
                <span class="c c1"></span>
                <span class="line activity">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="c c3 active"></span>
              </div>
              <div class="item_right">
                <div class="item_col">
                  <p>预付金额 ¥{{ orderInfo.pay_amount }}</p>
                  <p>已付款</p>
                </div>
                <div class="item_col">
                  <p>实际配捐 ¥{{ orderInfo.matching_amount }}</p>
                  <p v-if="orderInfo.difference_refund_amount">
                    已退款 ¥{{ orderInfo.difference_refund_amount }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="orderInfo.difference_refund_amount" class="together_detail three">
              <div class="item_left">
                <span class="c c1"></span>
                <span class="line">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="c c2"></span>
                <span class="line activity">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="c c3 active"></span>
              </div>
              <div class="item_right">
                <div class="item_col">
                  <p>预付金额 ¥{{ orderInfo.pay_amount }}</p>
                  <p>已付款</p>
                </div>
                <div class="item_col">
                  <p>实际配捐：¥{{ orderInfo.matching_amount }}</p>
                  <p>已退款 ¥{{ orderInfo.difference_refund_amount }}</p>
                </div>
                <div class="item_col">
                  <p>
                    配捐退款
                    <span class="icon icon-issue" @click="openrefund"></span>
                  </p>
                  <p>已退款 ¥{{ orderInfo.teamfund_refund_amount }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="!orderInfo.matching_amount" class="together_detail two">
              <div class="item_left">
                <span class="c c1 active"></span>
                <span class="line activityF">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span class="c c3"></span>
              </div>
              <div class="item_right">
                <div class="item_col">
                  <p>预付金额 ¥{{ orderInfo.pay_amount }}</p>
                  <p>已付款</p>
                </div>
                <div class="item_col">
                  <p>实际配捐</p>
                  <p>未生成</p>
                </div>
              </div>
            </div>
          </template>
        </template>
        <div v-if="orderInfo.trade_data.reward_contents" class="p" style="white-space: pre-line">
          {{ orderInfo.trade_data.reward_contents }}
        </div>
        <div v-if="orderInfo.trade_data.reward_pack_desc &&
          orderInfo.trade_data.reward_pack_desc.length
          ">
          <div class="openbox-text">
            <p v-for="e in orderInfo.trade_data.reward_pack_desc" :key="e">
              {{ e }}
            </p>
          </div>
        </div>
        <div v-if="orderInfo.trade_data.rew_logo &&
          orderInfo.trade_data.rew_logo.length
          " class="rew_logo">
          <img v-for="e in orderInfo.trade_data.rew_logo" :key="e" :src="$imgUrlFilter(e, imgWidth)" alt="" />
        </div>
      </div>
      <openCardlist v-if="orderInfo.card_info" :orderInfo="orderInfo" :orderId="orderInfo.order_id"
        :card_info="orderInfo.card_info"></openCardlist>
      <openPacklist v-if="orderInfo.pack_info" :orderInfo="orderInfo" :orderId="orderInfo.order_id"
        :pack_info="orderInfo.pack_info"></openPacklist>
      <div class="reward_time" v-if="orderInfo.trade_data.reward_day">
        <span>预计发放时间</span>
        <span>{{ orderInfo.trade_data.reward_day }}</span>
      </div>
    </div>
    <div class="conform" v-if="orderInfo">
      <!-- 运费 -->
      <div v-if="orderInfo.amount_info && orderInfo.amount_info.amount_line" class="freight-box">
        <div class="freight" v-for="(lineItem,lineIndex) in orderInfo.amount_info.amount_line" :key="lineIndex">
          <span>{{lineItem.title}}</span>
          <span>{{ lineItem.value }}</span>
        </div>
        <!-- 合计 -->
        <template v-if="orderInfo.amount_info && orderInfo.amount_info.total_amount_title">
          <div class="freight">
            <span>{{orderInfo.amount_info.total_amount_title}}</span>
            <span class="price2">{{orderInfo.amount_info.total_amount_value}}</span>
          </div>
        </template>
      </div>
      <div class="remark" v-if="orderInfo.remark">
        <div class="left">
          <span>订单备注</span>
        </div>
        <div class="remarktext">{{ orderInfo.remark }}</div>
      </div>
      <div class="order_paper">
        <p class="freight">
          <span>订单号</span>
          <input ref="copy" style="position: absolute; opacity: 0; width: 1px; height: 1px" :value="orderInfo.order_id" />
          <span @click="copy" class="copyp" :copyData="orderInfo.order_id">
            {{ orderInfo.order_id }}
            <el-button class="fuzhi" type="" size="small" text bg>复制</el-button>
          </span>
        </p>
        <p class="freight">
          <span>下单时间</span>
          <span>{{ orderInfo.ctime }}</span>
        </p>
        <p class="freight" v-if="orderInfo.pay_time && orderInfo.presale_type != 2">
          <span>付款时间</span>
          <span>{{ orderInfo.pay_time }}</span>
        </p>
        <p class="freight" v-if="orderInfo.first_pay_time">
          <span>定金支付时间</span>
          <span>{{ orderInfo.first_pay_time }}</span>
        </p>
        <p class="freight" v-if="orderInfo.tail_pay_time">
          <span>尾款支付时间</span>
          <span>{{ orderInfo.tail_pay_time }}</span>
        </p>
        <p class="freight" v-if="orderInfo.teamfund_id">
          <span>一起筹ID</span>
          <span>{{ orderInfo.teamfund_id }}
            <!-- <a @click="lookTeamfund" href="#" style=""
              >查看详情
              <img
                class="icon"
                src="~@assets/images/orderlist/yiqichouicon.png" /></a
          > -->
          </span>
        </p>

      </div>
    </div>
    <OpenApp ref="openapp" />
  </div>
</template>

<script>
import { domClientWidth, domPixelRatio } from "md-base-tools/env";
import openCardlist from "@/components/order_detail/openCardlist";
import openPacklist from "@/components/order_detail/openPacklist";
import OpenApp from "@@/common/openApp.vue";
import { ElMessageBox } from "element-plus";
import domain from '@/service/domain'

export default {
  name: "goodsInfo",
  props: {
    orderInfo: Object,
  },
  data() {
    return {
      display_scale: "1x1",
    };
  },
  computed: {
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    },
  },
  components: {
    openCardlist,
    openPacklist,
    OpenApp,
  },
  mounted() { },
  methods: {
    openrefund() {
      ElMessageBox.confirm(
        `<p>1、众筹项目失败;</p>
          <p>2、众筹项目被发起者或摩点取消;</p>
          <p>3、众筹项目发起者主动发起退款;</p>
          <p>4、其他</p>`,
        '配捐资金将原路退回的原因',
        {
          confirmButtonText: '知道了',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
        }
      )
    },
    copy() {
      var text = this.$refs.copy;
      text.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    openboxmore() {
      this.$emit("openboxmore");
    },
    //商品操作按钮
    // eslint-disable-next-line no-unused-vars
    op(type, oid, refund_id) {
      switch (type) {
        // 申请退款
        case "apply_refund":
          location.href = "/product/refund?soid=" + oid;
          break;
        // 申请售后
        case "apply_after":
          location.href = "/product/refund/apply?soid=" + oid;
          break;
        case "refund_success":
          break;
        default:
          location.href = "/product/refund?refund=" + refund_id;
      }
    },
    goProDetail(pro_id) {

      window.open(`${domain.zhongchou}/item/${pro_id}.html`)
    },
    goGoods(id) {
      if (
        this.orderInfo.extract_card_type &&
        this.orderInfo.extract_card_type > 0
      ) {
        location.href =
          "/luckycards/fastsell/detail/" +
          this.orderInfo.extract_card_id +
          ".html";
        return false;
      } else {
        location.href = `/product/${id}.html`;
      }
    },
    //客服按钮
    downApp() { },
  },
};
</script>

<style scoped lang="scss">
.openboxlist {
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  margin-top: 20px;
  .left {
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 24px;
  }
}
.box {
  margin-left: 40px;
  padding-bottom: 20px;
  flex: 1;
  .ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    .li {
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      // &:first-child {
      //   margin-left: 0;
      // }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      .unpacking_type {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 45px;
        height: 18px;
        background: linear-gradient(52deg, #00cba6, #21b6c7);
        border-radius: 0 0 0 2px;
        line-height: 18px;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        z-index: 9;
        display: none;
        &.unpacking_type1 {
          display: block;
        }
      }
      .img {
        position: relative;
        width: 78px;
        height: 78px;
        background: #f2f2f2;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #f2f2f2;
        img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        width: 78px;
        margin: 0 auto;
        margin-top: 5px;
        font-size: 12px;
        font-family: PingFang SC-常规体, PingFang SC;
        font-weight: normal;
        color: #7f7f7f;
        line-height: 18px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: center;
      }
    }
  }
}
.bt-box {
  text-align: center;
}
.bt {
  margin: 0 auto;
}
</style>

<template>
  <div class="openboxlist">
    <div class="left">拆盒结果</div>
    <div class="box">
      <div class="ul">
        <template v-for="(item, idx) in list">
          <div class="li" :key="idx" v-if="idx < 3 || !bt">
            <div class="img">
              <div
                :class="[
                  'unpacking_type',
                  'unpacking_type' + item.unpacking_type,
                ]"
              >
                隐藏款
              </div>
              <img :src="item.unpacking_icon" alt="" />
            </div>
            <div class="name">{{ item.unpacking_name }}</div>
          </div>
        </template>
      </div>
      <div class="bt-box" v-if="bt && pack_info.pack_total > 3">
        <el-button class="bt" @click="getlist" type="" size="small" text bg>查看全部({{ pack_info.pack_total }})</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import lowerdev from '@/service/lowerdev'
import { orderPacks } from "@api";
export default {
  name: "openboxlist",
  data() {
    return {
      mes: "",
      bt: true,
      list: [],
    };
  },
  components: {},
  watch: {},
  computed: {},
  props: ["orderId", "orderInfo", "pack_info"],
  mounted() {
    this.list = this.pack_info.pack_list;
  },
  methods: {
    toProject() {
      location.href = `/luckycards/container`;
    },
    close() {
      this.$emit("close");
    },
    getlist() {
      const order = {
        url: "/apis/mdorder/order_packs",
        method: "get",
        props: {
          order_id: this.orderId,
          page: 1,
          page_size: 999,
        },
      };
      orderPacks(order.props).then((e) => {
        if (e.status == 0) {
          this.list = e.data.list;
          this.bt = false;
        }
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    },
  },
};
</script>
